p {
  font-family: $csLight;
  font-size: 0.85rem;
  line-height: 1.3;
  color: $dark-forest-green;
}
a, a:hover, a:focus {
  color: $dark-forest-green;
  text-decoration: none;
}
h2 {
    font-family: $csBlack;
    font-size: 0.93rem!important;
    text-transform: uppercase;
    margin-bottom: 20px;
}
h3 {
    font-family: $csBlack;
    font-size: 0.9rem!important;
}

.paragraphList {
  font-size: 0.8rem;
  list-style: disc;
  margin-left: 20px;
}

a.logo-text {
    font-size: 22px;
    font-family: CarlsbergSansW00-Light;
    text-transform: uppercase;
    text-decoration: none!important;
    color: #fff;
    line-height: 0.5;
}

a.logo-text span.logo-text-bold {
    font-family: CarlsbergSansW00-Black;
}
