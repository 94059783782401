.btn-primary.carlsberg, .btn-primary.carlsberg:not(:disabled):not(.disabled):active, .btn-primary.carlsberg:not(:disabled):not(.disabled):active:focus, .btn-primary:disabled.carlsberg {
    font-family: $csBlack;
    font-size: 0.85rem;
    color: #fff;
    background-color: $bluish-green;
    border-color: $bluish-green;
    border-radius: 0px;
    text-transform: uppercase;
    text-decoration: none;
    padding: 10px;
    outline: none;
    box-shadow: none;
}
.btn-secondary.carlsberg, .btn-secondary:disabled.carlsberg {
    font-family: $csBlack;
    font-size: 0.85rem;
    color: $dark-forest-green;
    background-color: #fff;
    border-color: $dark-forest-green;
    border-radius: 0px;
    padding: 10px;
    text-transform: uppercase;
}
.btn-primary.languageBtn, .btn-primary.languageBtn:not(:disabled):not(.disabled):active, .btn-primary.languageBtn:not(:disabled):not(.disabled):active:focus, .btn-primary.languageBtn:disabled {
  background: #fff;
  border: none;
  color: #00321d;
  text-align: left;
  padding: 0 0 10px;
  margin-bottom: 10px;
  border-radius: 0px;
  border-bottom: 1px solid #ffb400;
  outline: none;
  box-shadow: none;
}
