.nav {
  background: $yellow-orange;
}
.nav-item {
  display: inherit;
  text-decoration: none;
}
.nav .nav-item a {
  font-family: $csBold;
  font-size: 0.8rem;
  padding: 10px;
  color: #fff;
  text-decoration: none;
}

// Floating Footer
nav.floatingCta {
  background: #f9f9f9;
  padding: 20px;
  box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.16);
}
.navClear {
  margin-bottom: 110px;
}
.empty.navClear {
  display: block;
  margin-bottom: 100px;
}
