@import "./styles/fonts.scss";
@import "./styles/variables.scss";
@import "./styles/text.scss";
@import "./styles/navigation.scss";
@import "./styles/buttons.scss";

html, body, #root {
  font-family:$csLight;
  background: #E9E8E9;
  color: $dark-forest-green;
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: auto;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
ul {
  border-radius: 8px;
  padding: 0px;
  margin-bottom: 10px!important;
}
.table-bordered td, .table-bordered th {
    border: 1px solid #dee2e6;
    max-width: 180px;
}
img.logo-image {
    display: block;
    float: right;
}
/* Countdown */
.countdownSection {
    padding: 25px;
    margin-bottom: 20px;
    border-bottom: 1px solid #d3d3d3;
}
span.CountdownNumber {
    font-family:$csBlack;
    display: inline-block;
    font-size: 18px;
    background: $carl-gold;
    border-radius: 5px;
    padding: 6px;
    width: 40px;
    height: 40px;
    margin-right: 5px;
    color: #fff;
    text-align: center;
    letter-spacing: 0.1rem;
}
span.CountdownText {
  font-size: 14px;
  color: $carl-gold;
  margin-right: 15px;
}
img.img-fluid {
    width: 100%;
}
.checkIcon {
    display: block;
    width: 80px;
    height: auto;
    margin: 35px auto 40px;
}
label.inputLabel {
    font-family: $csBlack;
    font-size: 0.9rem!important;
    display: inline-block;
    margin-bottom: 0rem;
}
.form-check {
    font-size: 0.9rem;
    margin: 15px auto;
}
.consentSection.form-group {
    background: #ffb400;
    padding: 15px 20px;
    color: #fff;

    label {
      font-family: $csBold;
      font-size: 0.75rem;
    }

    ul {
      font-size: 0.75rem;
      margin-left: 20px;
    }

    a, a:hover {
      color: #fff;
      text-decoration: underline;
      font-style: italic;
    }
}
.form-control, .ap-input {
    border: none!important;
    border-radius: 0px!important;
    border-bottom: 2px solid $yellow-orange!important;
    padding-left: 0px!important;
}
.ap-input::placeholder {
    color: #797979!important;
}
.form-control:focus, .ap-input:focus {
    color: #495057;
    background-color: #fff;
    border-color: $yellow-orange;
    border-bottom: 2px solid $yellow-orange;
    outline: 0;
    box-shadow: none!important;
}

.modal {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    overflow: hidden;
    border-radius: 0px;
}
.modal-backdrop {
  background: none;
}
.modal-title {
  position: relative;
  display: block;
  width: 100%;
  height: 120px;
  top:0;
}
.modal-dialog {
    margin: 0;
    max-width: 100%!important;
    height: 100%;
    padding: 0;
    border-radius: 0px;
}
.modal-content {
    position: relative;
    display: block;
    width: 100%;
    height: 100%;
    pointer-events: auto;
    background-color: #fff;
    background-clip: padding-box;
    border: none;
    outline: 0;
    text-align: center;
    border-radius: 0px;
    padding: 0px;
}
.pageContainer {
  position: relative;
  width: 100%;
  margin: 0 auto;
  background: #fff;
  padding-bottom: 40px;
}
.route-wrapper {
  position: relative;
  width: 100%;
  margin: 0 auto;
}
.route-wrapper > span {
  position: absolute;
  width: 100%;
  background: #fff;
}

@media screen and (min-width: 700px) {
  .nav .nav-item a {
    padding: 10px 30px;
  }
  .pageContainer {
    position: relative;
    width: 80%;
    margin: 0 auto;
    background: #fff;
    height: 100%;
    padding-bottom: 40px;
  }
  .route-wrapper {
    position: relative;
    width: 80%;
    margin: 0 auto;
  }
  .route-wrapper > span {
    position: absolute;
    width: 100%;
    background: #fff;
  }
  .btn-primary.carlsberg, .btn-secondary.carlsberg {
    margin: 0 auto;
    width: 400px;
  }
}

@media screen and (min-width: 1000px) {
  .pageContainer {
    position: relative;
    width: 60%;
    margin: 0 auto;
    background: #fff;
    height: 100%;
    padding-bottom: 40px;
  }
  .route-wrapper {
    position: relative;
    width: 60%;
    margin: 0 auto;
  }
  .route-wrapper > span {
    position: absolute;
    width: 100%;
    background: #fff;
  }
  .btn-primary.carlsberg, .btn-secondary.carlsberg {
    width: 400px;
    margin: 0 auto;
  }
}
